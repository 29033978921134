import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LetrusLogo from 'assets/images/letrusLogo.svg';
import DropdownMenu, {ItemProps} from 'components/DropdownMenu';

import {Link, useLocation} from 'react-router-dom';
import forceLogout from 'utils/functions/forceLogout';
import styles from './PageLayout.module.scss';

interface PageLayoutProps {
  children: React.ReactNode;
}

function PageLayout({children}: PageLayoutProps): React.ReactNode {
  const location = useLocation();

  const headerMenuOptions: ItemProps[] = [
    {
      id: 1,
      text: 'Django Admin',
      icon: 'external-link',
      onClick: () => window.open(import.meta.env.VITE_APP_ADMIN_API_URL),
    },
    {
      id: 2,
      text: 'Sair',
      icon: 'sign-out',
      onClick: () => forceLogout(),
    },
  ];

  const sidebarOptions: {
    label: string;
    icon: IconProp;
    path: string;
    active: boolean;
  }[] = [
    {
      label: 'Home',
      icon: ['fad', 'home'],
      path: '/',
      active: location.pathname === '/',
    },
    {
      label: 'Atividades',
      icon: ['fad', 'files'],
      path: '/atividades',
      active: location.pathname === '/atividades',
    },
    {
      label: 'Usuários',
      icon: ['fad', 'users'],
      path: '/usuarios',
      active: location.pathname === '/usuarios',
    },
    {
      label: 'Clientes',
      icon: ['fad', 'handshake'],
      path: '/clientes',
      active: location.pathname === '/clientes',
    },
    {
      label: 'Notificações',
      icon: ['fad', 'bell'],
      path: '/notificacoes',
      active: location.pathname === '/notificacoes',
    },
  ];

  return (
    <>
      <header className={styles.pageHeader}>
        <img src={LetrusLogo} alt="logo" />
        <DropdownMenu items={headerMenuOptions} title="Menu" />
      </header>

      <main className={styles.mainContent}>
        <nav>
          <ul className={styles.sidebar}>
            {sidebarOptions.map((option) => (
              <li key={option.path}>
                <Link to={option.path} className={styles.navLink}>
                  <FontAwesomeIcon
                    icon={option.icon}
                    color="#FFF"
                    size="2x"
                    className={option.active ? styles.active : ''}
                  />
                  {option.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <section className={styles.contentWrapper}>{children}</section>
      </main>
    </>
  );
}

export default PageLayout;
