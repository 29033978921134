import createAPI from 'utils/createAPI';
import forceLogout from 'utils/functions/forceLogout';

const letrusAdminAPI = createAPI({
  timeout: 360 * 1000,
  withCredentials: true,
  onUnauthorized: forceLogout,
  baseURL: `${import.meta.env.VITE_APP_API_URL}/admin_api`,
});

export default letrusAdminAPI;
