import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {createRef, ReactNode, useEffect, useState} from 'react';
import styles from './DropdownMenu.module.scss';

type onClick = (event: React.SyntheticEvent) => void;

export type ItemProps = {
  id: string | number;
  text?: string;
  icon?: IconProp;
  active?: boolean;
  onClick: onClick;
};

export interface DropdownMenuProps {
  items: ItemProps[];
  title: string;
}

function DropdownMenu({
  items = [],
  title = '',
}: DropdownMenuProps): React.ReactNode {
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [currentValue, setCurrentValue] =
    useState<number | null | string>(null);
  const [listItems, setListItems] = useState<Array<ItemProps>>(items);
  const toggleListVisibility = (): void => {
    setIsListVisible(!isListVisible);
  };

  const allItemsDisabled = (): Array<ItemProps> =>
    listItems.map(
      (item: ItemProps): ItemProps =>
        item.active ? {...item, active: false} : item,
    );

  const selectItem = (
    event: React.SyntheticEvent,
    itemSelected: ItemProps,
  ): void => {
    itemSelected.onClick(event);
    setListItems(allItemsDisabled());
    toggleListVisibility();
    setCurrentValue(itemSelected.id);
  };

  const wrapperRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    function handleClickOutsideLabel(event): void {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsListVisible(false);
      }
    }

    document.addEventListener('click', handleClickOutsideLabel);
    return (): void => {
      document.removeEventListener('click', handleClickOutsideLabel);
    };
  }, [wrapperRef]);

  return (
    <div className={styles.dropdownMenu}>
      <button
        type="button"
        ref={wrapperRef}
        className={styles.dropdownMenuLabel}
        onClick={toggleListVisibility}
        tabIndex={0}
        data-testid="labelButton"
      >
        <span>{title}</span>
        <div className={styles.arrowWrapper}>
          <FontAwesomeIcon icon="sort-down" size="lg" />
        </div>
      </button>

      {isListVisible && (
        <div className={styles.wrapperDropdownList}>
          <ul className={styles.dropdownMenuList}>
            {listItems.map(
              (item: ItemProps): ReactNode => (
                <li
                  role="menuitem"
                  onKeyDown={(event): void => selectItem(event, item)}
                  onClick={(event): void => selectItem(event, item)}
                  tabIndex={0}
                  key={item.id}
                  className={`${styles.dropdownMenuItem} ${
                    item.active || currentValue === item.id
                      ? styles.selected
                      : ''
                  }`}
                >
                  {item.icon && (
                    <div className={styles.iconItem}>
                      <FontAwesomeIcon size="lg" icon={item.icon} />
                    </div>
                  )}
                  <div className={styles.textItem}>{item.text}</div>
                </li>
              ),
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default DropdownMenu;
