import ScreenLoader from 'components/ScreenLoader';
import {useLayoutEffect} from 'react';
import {RouterProvider} from 'react-router-dom';
import {router} from 'routes';
import {setupTrackers} from 'setupTrackers';
import './App.scss';

function App(): React.ReactNode {
  useLayoutEffect(() => {
    setupTrackers();
  }, []);

  return <RouterProvider router={router} fallbackElement={<ScreenLoader />} />;
}

export default App;
