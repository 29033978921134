import AuthRoute from 'components/AuthRoute';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="*" lazy={() => import('pages/NotFound')} />
      <Route path="login" lazy={() => import('pages/Login')} />

      <Route element={<AuthRoute />}>
        <Route path="" lazy={() => import('pages/Home')} />

        <Route path="atividades">
          <Route path="" lazy={() => import('pages/Activities')} />

          <Route
            path="questionarios"
            lazy={() => import('pages/Activities/QuestionnairesCRUD')}
          />

          <Route
            path="temas"
            lazy={() => import('pages/Activities/ThemesCRUD')}
          />

          <Route path="trilhas">
            <Route
              path=""
              lazy={() => import('pages/Activities/LearningPathCRUD')}
            />

            <Route
              path="templates"
              lazy={() => import('pages/Activities/LearningPathTemplateCRUD')}
            />
          </Route>

          <Route path="escritas">
            <Route
              path=""
              lazy={() => import('pages/Activities/CompositionsCRUD')}
            />

            <Route
              path="templates"
              lazy={() => import('pages/Activities/TestTemplatesCRUD')}
            />
          </Route>
        </Route>

        <Route path="usuarios">
          <Route path="" lazy={() => import('pages/Users')} />

          <Route
            path="gerenciar"
            lazy={() => import('pages/Users/UsersCRUD')}
          />

          <Route
            path="dashboards/:userRole"
            lazy={() => import('pages/Users/ManagerDashboards')}
          />

          <Route
            path="upload-planilha/:registrationType"
            lazy={() => import('layouts/MassiveRegistration')}
          />
        </Route>

        <Route path="clientes">
          <Route path="" lazy={() => import('pages/Clients')} />

          <Route
            path="redes"
            lazy={() => import('pages/Clients/NetworksCRUD')}
          />

          <Route
            path="escolas"
            lazy={() => import('pages/Clients/SchoolsCRUD')}
          />

          <Route
            path="turmas"
            lazy={() => import('pages/Clients/SchoolGroupCRUD')}
          />

          <Route
            path="contratos"
            lazy={() => import('pages/Clients/ContractsCRUD')}
          />

          <Route
            path="upload-planilha/:registrationType"
            lazy={() => import('layouts/MassiveRegistration')}
          />
        </Route>

        <Route path="notificacoes">
          <Route path="" lazy={() => import('pages/Notifications')} />

          <Route
            path=":userRole/enviar"
            lazy={() => import('pages/Notifications/NotificationsSend')}
          />

          <Route
            path=":userRole/criar-grupo"
            lazy={() => import('pages/Notifications/NotificationGroupCreate')}
          />
        </Route>
      </Route>
    </Route>,
  ),
);
